<template>
  <div class="main__description">
    <h1>{{productDetail.displayName }} </h1>    
    <div class="text-justify" >
    <div v-html="productDetail.description"></div>
    </div> 
    <hr>
    <h2 class="product__terms" v-if="productDetail.termsConditions">{{ $t('itemDetail.productPolicies') }} </h2>
    <div v-html="productDetail.termsConditions" ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

import { market } from '@/modules/shop/mixins/market'

export default {
  
  mixins: [ market ], 
  directives: {
    Ripple,
  },
 

  data() {
    return {
      slugFromUrl: '',
      payloadPeticion: null,
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
      isLoadingProduct: false,
      lastPath: null,
      dateDefault: null,
      index: null

    }
  },
  computed:{    
    ...mapState('products',['productDetail']),
  },
}
</script>

<style lang="scss" scoped>  
  @import "@/assets/scss/item-description.scss";  
</style>
