<template>
	<div class="item__picture" >
		<h1>{{productDetail.name }}</h1>    
		<!-- 
			<CoolLightBox :items="productDetail.gallery"  :index="index" @close="index = null" v-if="productDetail.gallery.length > 0"/> 
			<div class="view-gallery" v-if="productDetail.gallery.length > 0" @click="index = 0" >
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);transform: ;msFilter:;">
				<path d="M4 4h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 10h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4zM4 16h4v4H4zm6 0h4v4h-4zm6 0h4v4h-4z"></path>
			</svg>
			<span class="text-action-gallery">Show Gallery</span> 
			
			</div>
		-->
	
		<img :src="imgProduct" :alt="`Photo ${productDetail.name}`" @error="imgAlternativo" class="item__img" @click="index = 0" > 
		<!-- <img :src="linkImage" :alt="`Photo ${productDetail.name}`" @error="imgAlternativo" class="item__img" @click="index = 0" >  -->
	</div> 
</template>

<script>
import { mapState } from 'vuex'
import CoolLightBox from 'vue-cool-lightbox'
import { market } from '@/modules/shop/mixins/market'

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {  
  	mixins: [ market ], 
	components: {   
		CoolLightBox,       
	},
	data() {
		return {             
			index: null,
		}
	},
	computed:{    
		...mapState('products',['productDetail']),
		imgProduct(){
			let img
			if(this.productDetail.modelType=="rates"){
				img=`${process.env.VUE_APP_IMGEXP_SRC_API}${this.productDetail.coverImage}`
			}else{
				img= `${process.env.VUE_APP_BASE_URL_SERVER}${this.productDetail.coverImage}`
			}
			return img;
		}                
	},
	methods: {    
		imgAlternativo( event ){
			event.target.src = require('@/assets/images/default.jpg')
		},                
	},
 
}
</script>

<style lang="scss" scoped>  
  @import "@/assets/scss/item-image.scss";  
</style>
